import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/components/LayoutDefault.js";
import LayoutContact from "../components/LayoutContact";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LayoutContact;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <div className="typeform-widget fixed-width" data-url="https://benjamingeck.typeform.com/to/gl9wA5" data-transparency="100" data-hide-headers={true} data-hide-footer={false} style={{
      width: "100%",
      height: "500px",
      display: "grid",
      alignItems: "center",
      textAlign: "center",
      color: "#d5d5d6"
    }}>
  <h1>Connecting...</h1>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      